<template>
  <svg width="22" height="13" viewBox="0 0 22 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.4829 5.88788C18.8926 5.24734 18.227 4.61937 17.4985 4.02907C15.4388 2.37121 13.2785 1.37901 11.1559 1.37901C9.03333 1.37901 6.87311 2.37121 4.81334 4.02907C4.08489 4.61937 3.40665 5.2599 2.82891 5.88788C2.71587 6.00091 2.61541 6.11396 2.52749 6.22699C2.61541 6.32747 2.71587 6.44051 2.82891 6.5661C3.41921 7.20664 4.08489 7.83461 4.81334 8.42491C6.87311 10.0828 9.03333 11.075 11.1559 11.075C13.2785 11.075 15.4388 10.0828 17.4985 8.42491C18.227 7.83461 18.9051 7.19408 19.4829 6.5661C19.5959 6.45307 19.6964 6.34003 19.7843 6.22699C19.6838 6.12652 19.5834 6.01347 19.4829 5.88788ZM11.1434 11.8788C5.79303 11.8788 1.45996 6.22699 1.45996 6.22699C1.45996 6.22699 5.79303 0.575195 11.1434 0.575195C16.4938 0.575195 20.8268 6.22699 20.8268 6.22699C20.8268 6.22699 16.4938 11.8788 11.1434 11.8788ZM11.1434 9.8567C13.1529 9.8567 14.7731 8.22396 14.7731 6.22699C14.7731 4.23002 13.1404 2.59728 11.1434 2.59728C9.13387 2.59728 7.51367 4.23002 7.51367 6.22699C7.51367 8.22396 9.13387 9.8567 11.1434 9.8567ZM11.1434 9.05289C9.58602 9.05289 8.3175 7.78438 8.3175 6.22699C8.3175 4.66961 9.58602 3.40109 11.1434 3.40109C12.7008 3.40109 13.9693 4.66961 13.9693 6.22699C13.9693 7.78438 12.7008 9.05289 11.1434 9.05289Z"
      fill="currentColor"
      stroke="currentColor"
      stroke-miterlimit="10"
    />
  </svg>
</template>
